body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* button {
  height: 20px;
  width: 100px;
} */