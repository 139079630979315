.App {
	display: flexbox;
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}


/* =========edited=================== */

.input-label.MuiFormLabel-root.Mui-focused,
.MuiFormLabel-root.MuiInputLabel-root.input-label {
	/* color: rgba(0, 80, 110, 1) !important;
	font-weight: 700 !important; */
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
	text-transform: uppercase !important;
	font-size: small !important;
	opacity: 1 !important;
}

.input-label+.MuiInput-underline:before,
.input-label+.MuiInput-underline:after {
	/* border-bottom: 1px solid rgba(0, 80, 110, 1) !important; */
	border-bottom: 1px solid rgba(20, 188, 206, 0.42) !important;
}

.invalid-feedback {
	color: red;
	float: left;
	align-self: baseline;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
	border-bottom: 1px solid rgba(20, 188, 206, 0.42) !important;
}

.text-field .MuiInput-underline::before,
.MuiInput-underline::after {
	/* border-bottom: 1px solid rgba(0, 80, 110, 1) !important; */
	border-bottom: 1px solid rgba(20, 188, 206, 0.42) !important;
}

.MuiFormLabel-root.Mui-focused {
	color: inherit !important;
	font-weight: 700 !important;
}

.MuiInput-underline.Mui-error:after {
	transform: scaleX(1) !important;
	border-bottom-color: #f44336 !important;
}

.footer {
	background-color: #0099FF !important;
	top: auto;
	bottom: 0;
}

.profileHeading {
	text-align: left;
}

.test {
	height: 90px !important;
	width: 90px !important;
}

.form-control {
	float: inherit;
	width: 100% !important;
	margin-bottom: 25px !important;
	margin-right: 25px !important;
}

.two-button-grid {
	margin-bottom: 25px !important;
}

.update-button {
	background-color: #0099FF !important;
}

.login-component {
	background-image: url(./images/leftBanner.svg);
	min-height: 100vh;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	width: 70%;
	z-index: 99999;
}

.login-component {
	display: none;
}

.keepLoggedIn {
	color: #656565;
	float: left;
	font: normal normal small 12px/15px Lato;
	font-size: small;
}

.forgotPassword {
	color: #FF6E6E;
	text-decoration: underline;
	float: right;
	font: normal normal small 12px/15px Lato;
	font-size: small;
}

@media only screen and (min-width: 768px) {
	.login-component {
		display: block;
	}
}

.form-label {
	padding: 0px !important;
}

.card-container {
	border-right: solid;
	border-left: solid;
	border-bottom: solid;
	border-width: 1px;
	border-color: #0098FF;
	box-shadow: 0px 3px 3px #00000066;
	margin: 10%;
	margin-bottom: 20%;
}

.card-header-1 {
	padding: 0px !important;
	background: #AAE5FF !important;
	font: "normal normal small 15px/18px Lato" !important;
	font-size: small !important;
	letter-spacing: 0px !important;
	color: #000000 !important;
	height: 100% !important;
	width: 100% !important;
	text-align: center !important;
	border-radius: 100% !important;
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
	text-transform: uppercase !important;
}

.card-header-2 {
	color: #0099FF !important;
	padding-bottom: inherit !important;
	/* opacity: 100% !important; */
}

.login-button {
	background-color: #ECF9FF !important;
	border: solid !important;
	border-color: #0098FF5C !important;
	border-width: 1px !important;
}

.login-form-control {
	float: inherit !important;
	width: 99% !important;
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px !important;
}

.mb-25 {
	margin-bottom: 25px !important;
}

.registration-form {
	margin-bottom: 10px !important;
}

.MuiInput-underline.Mui-error:after {
	transform: scaleX(1) !important;
	border-bottom: 1px solid #f44336 !important;
}

.profile-page-avatar {
	height: 90px !important;
	width: 90px !important;
	background-color: rgba(0, 143, 165, 1);
	margin-top: 4% !important;
	border: 2px solid !important;
}

.login-card {
	background-color: transparent !important;
	height: 195px !important;
	width: 45%;
	margin-top: 10%;
	box-shadow: 0px 0px 0px #00000080 !important;
	border: 0.5px solid #FFFFFF !important;
	opacity: 1 !important;
}

.login-card-header {
	color: #FFFFFF !important;
	font-size: 22px !important;
	font: normal normal 600 22px/27px Montserrat;
}

.login-card-body {
	letter-spacing: 0.36px !important;
	color: #FFFFFF !important;
	opacity: 1 !important;
	font-size: 18px !important;
	margin-top: 40px !important;
	font: normal normal normal 18px/22px Montserrat !important;
}

.registration-card {
	background-color: transparent !important;
	height: 80% !important;
	width: 58%;
	margin-top: 10%;
	box-shadow: 0px 0px 0px #00000080 !important;
	border: 1px solid #FFFFFF !important;
	opacity: 1 !important;
	border-top-left-radius: 20px !important;
	border-bottom-right-radius: 20px !important;
}

.registration-card-header {
	color: #FFFFFF !important;
	font-size: 22px !important;
	font: normal normal 600 22px/27px Montserrat;
}

.registerStyleCardSubHeader {
	color: #3E3E3E;
	font: normal normal medium 20px/24px Montserrat;
	margin-top: 5%;
}

.registration-card-body {
	letter-spacing: 0.36px !important;
	color: #FFFFFF !important;
	opacity: 1 !important;
	font-size: 18px !important;
	margin-top: 40px !important;
	font: normal normal normal 18px/22px Montserrat !important;
}

.circle {
	padding: 0px !important;
	background: #AAE5FF !important;
	color: #000000 !important;
	height: 80px !important;
	width: 80px !important;
	border-radius: 100% !important;
	text-align: center;
	align-content: center;
}

.registration-page-avatar {
	margin-left: 42%;
	height: 70px !important;
	width: 70px !important;
	background-color: #AEFFFAC2 !important;
	margin-top: 4% !important;
}

.remember-me {
	color: #656565 !important;
	float: left !important;
	font: normal normal small 12px/15px Lato !important;
	font-size: small !important;
}

.link {
	color: #00B1FF !important;
	margin-left: 10px;
}

.profileSection .profileBlock .profileHeader {
	background: #00637D !important;
	height: 88px;
}

.profileSection .profileBlock .profileBody {
	background: #FFF !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	min-height: 300px;
	padding-top: 40px;
}

.profileSection .MuiInputBase-input {
	color: #000 !important;
}

.profileSection .input-label.MuiFormLabel-root.Mui-focused,
.profileSection .login-form-control .form-label .MuiGrid-item {
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
	font-size: small !important;
}

.profileSection .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused,
.profileSection .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
	font-size: small !important;
	text-transform: uppercase;
	opacity: 1 !important;
}

.profileSection .MuiPaper-root .login-form-control .MuiGrid-root {
	color: rgb(101, 101, 101) !important;
	text-transform: uppercase;
	font-weight: 400 !important;
	font-size: small !important;
}

.profileSection .text-field .MuiInput-underline::before,
.profileSection .text-field .MuiInput-underline::after {
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}


/* .profileSection .update-button {
	background: #40B3FF !important;
} */

.profileSection .profile-page-avatar {
	background-color: rgba(0, 143, 165, 1) !important;
}


/* .profileSection .cancelBtn {
	border-color: white !important;
	color: white !important
} */

.profileSection .text-field .MuiInput-root {
	width: 95%;
}

input::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Firefox < 19 */

input:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Firefox > 19 */

input::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
}


/* Internet Explorer 10 */

input:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

input:focus:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
}

.profileAvatar {
	background: #FFF !important;
	color: #00637D !important;
	
}

.my-btn-container {
	text-align:center;
	margin-bottom:30px;
	
}
.my-btn-container.btn-right {
	text-align:right !important;
	
}
.my-btn-container button{
	margin:10px;

	
}
@media(min-width:400px) {
	.my-btn-container button:first-child{
		margin-left:-20px;
		
	}
}
@media(max-width:399px) {
	.my-btn-container button:first-child{
		margin-right:10px;
		
	}
	.my-btn-container button{
		margin:1px;
		
	}
	.my-btn-container.btn-right {
		text-align:center !important;
		
	}
	.changePasswordStyle .my-btn-container.btn-right {
		text-align:center !important;	
	}
	.resetPasswordSection {
		margin-top:30px !important

	}
}
@media(max-width:359px) {
	
	.my-btn-container button{
		margin-bottom: 20px;
		display: block;
		width:100%
		
	}
	.my-btn-container a.MuiButton-root{
		margin-bottom: 20px;
		display: block;
		width:100%
		
	}
}

/* --------change password section start------ */

.changePasswordSection .changePasswordBlock .changePasswordHeader {
	background: #00637D !important;
	height: 88px;
}

.changePasswordSection .changePasswordBlock .changePasswordBody {
	background: #FFF !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	min-height: 300px;
	padding-top: 40px;
}

.changePasswordSection .MuiInputBase-input {
	color: #000 !important;
}

.changePasswordSection .input-label.MuiFormLabel-root.Mui-focused,
.changePasswordSection .MuiFormLabel-root.MuiInputLabel-root.input-label {
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
	font-size: small !important;
	text-transform: uppercase;
	opacity: 1 !important;
}
.changePasswordSection .input-label+.MuiInput-underline:before,
.changePasswordSection .input-label+.MuiInput-underline:after {
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.changePasswordSection .MuiInput-underline.Mui-error:after {
	transform: scaleX(1) !important;
	border-bottom: 1px solid #f44336 !important;
}
.changePasswordSection .two-button-grid {
	margin-bottom: 25px !important;
	padding: 1%;
}
/* --------change password section end------ */


/* Login - Register - Forget Password */

.login-form-control .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused,
.login-form-control .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
	color: rgb(101, 101, 101) !important;
	font-weight: 400 !important;
	font-size: small !important;
	text-transform: uppercase;
	opacity: 1 !important;
}

.input-filed {
	width: 100%;
}

.login-field-container .form-icon {
	position: relative;
	top: 16px;
}

.login-form-control {
	margin-bottom: 15px !important;
}

@media(max-width:400px) {
	.login-field-container .form-icon {
		margin-left: -10px;
	}
	.login-form-control .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused,
    .login-form-control .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {

		font-size: 11px !important;

   }

}

/* Login End */


/* successpage css start */

#successPage,
#failurePage {
	position: relative;
	height: 100vh;
	/* width: 100%; */
	background-color: #4950570d;
	height: 400px;
}

#successPage .successPage,
#failurePage .failurePage {
	position: absolute;
	left: 50%;
	/* width: 94%; */
	top: 50%;
	justify-content: space-between;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.successmsg,
.failuremsg {
	margin: 2% !important;
}

.successmsg .message>h3,
.failuremsg .message>h3 {
	font-weight: 400;
}

.successPage a,
.failurePage a {
	font-family: 'Maven Pro', sans-serif;
	font-size: 14px;
	text-decoration: none;
	text-transform: uppercase;
	background: #189cf0;
	display: inline-block;
	padding: 16px 38px;
	border: 2px solid transparent;
	border-radius: 40px;
	color: #fff;
	font-weight: 400;
	-webkit-transition: 0.2s all;
	transition: 0.2s all;
}

.successPage,
.failurePage {
	max-width: 64%;
	/* max-width: 920px; */
	width: 100%;
	line-height: 1.4;
	text-align: center;
	padding-left: 32px;
	padding-right: 15px;
}
/* successpage css end */

.pt-60 {
	padding-top: 60px;
}

.mt-2 {
	margin-top: 2% !important;
}

.header-logo {
	display: block !important;
	width: auto !important;
	height: 72px !important;
	padding-top: 0px;
	/* padding-bottom: 11px;*/
	

}

.MuiDrawer-modal {
	z-index: 999999 !important;
}

.MuiPopover-paper {
	z-index: 999999 !important;
}

.hr-border {
	color: #cad0c4 !important;
	width: 50% !important;
}

.form-icon-fill {
	fill: #0098FF5C !important;
}

.password-icon {
	background: none !important;
	opacity: 0.5;
	transition: none !important;
}

.password-icon:hover,
.password-icon:active,
.password-icon:focus {
	opacity: 0.8;
}
/* Media Queries */
@media (min-width:1280px) and (max-width:1440px) {
	.login-component {
		background-position: -50px 0;
	}
}

@media (min-width:1101px) and (max-width:1279px) {
	.login-component {
		background-position: -250px 0;
		width: 60%;
	}
}

@media (min-width:960px) and (max-width:1100px) {
	.login-component {
		background-position: -340px -100px;
		width:50%;
	}
}

@media (min-width:600px) and (max-width:959px) {
	.login-component {
		min-height: 275px;
		background-position: center;
		width: 100%;
		background: #0099FF;	
	}
}

@media (min-width:768px) and (max-width:959px) {
	.form-area {
		margin-top: 280px !important;
	}
	.register-form-area {
		margin-top: 150px !important;
	}
	.registration-card {
		margin-top: 3% !important;
		margin-left: 2.2%;
		width: 95% !important;
	}
	.login-card {
		margin-top: 3% !important;
		margin-left: 2.2%;
		width: 95% !important;
	}
	.registration-card-body {
		margin-top: 2px !important;
	}
	.registration-card-header .MuiTypography-h5 {
		margin-top: 1% !important;
	}
	.registerStyle .registerStyleItem .registerStyleCardSubHeader {
		margin-top: 1% !important;
	}
	.registration-card .MuiCardContent-root {
		padding: 0 !important;
		padding-top: 10px !important;
	}
	.registration-card .MuiCardContent-root:last-child {
		padding-bottom: 10px !important;
	}
}

@media(min-width:960px) and (max-width:1279px) {
	.registration-card {
		margin-left: 20px;
		width: 50% !important;
	}
	.login-card {
		margin-left: 20px;
		width: 50% !important;
	}
}

@media(min-width:960px) and (max-width:1110px) {
	.registration-card {
		margin-top: 2% !important;
		width: 48% !important;
	}
}

@media only screen and (min-width: 768px) {
	.login-component {
		display: block;
	}
}

@media only screen and (max-width: 767px) {
	.pt-60 {
		padding-top: 40px !important;
	}
	.registerStyle .registerStyleItem {
		padding-top: 30px !important;
	}
	footer .MuiListItem-root a {
		padding: 0.9375rem 0.2rem !important;
		font-size: 11px !important;
	}
}

@media only screen and (max-width: 359px) {
	footer .MuiListItem-root a {
		padding: 0.9375rem 0.2rem !important;
		font-size: 10px !important;
	}
	.signin-text {
		font-size: 12px !important;
	}
}

/* Styles of Internet Explorer */

/* IE10+ */

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	input[required] {
		border: none !important;
		box-shadow: none;
		outline: none !important;
	}
	input:required:invalid {
		outline: none !important;
		border: none !important;
	}
	textarea:required:invalid {
		outline: none !important;
		border: none !important;
	}
	select:required:invalid {
		outline: none !important;
		border: none !important;
	}
	input[type=password]::-ms-reveal,
	input[type=password]::-ms-clear {
		display: none;
	}
}

/* IE6,7 */

@media screen\9 {
	input[required] {
		border: none !important;
		box-shadow: none;
		outline: none !important;
	}
	input:required:invalid {
		outline: none !important;
		border: none !important;
	}
	textarea:required:invalid {
		outline: none !important;
		border: none !important;
	}
	select:required:invalid {
		outline: none !important;
		border: none !important;
	}
	input[type=password]::-ms-reveal,
	input[type=password]::-ms-clear {
		display: none;
	}
}


/* IE8 */

@media \0screen {
	input[required] {
		border: none !important;
		box-shadow: none;
		outline: none !important;
	}
	input:required:invalid {
		outline: none !important;
		border: none !important;
	}
	textarea:required:invalid {
		outline: none !important;
		border: none !important;
	}
	select:required:invalid {
		outline: none !important;
		border: none !important;
	}
	input[type=password]::-ms-reveal,
	input[type=password]::-ms-clear {
		display: none;
	}
}


/* IE6,7,8 */

@media \0screen\,screen\9 {
	input[required] {
		border: none !important;
		box-shadow: none;
		outline: none !important;
	}
	input:required:invalid {
		outline: none !important;
		border: none !important;
	}
	textarea:required:invalid {
		outline: none !important;
		border: none !important;
	}
	select:required:invalid {
		outline: none !important;
		border: none !important;
	}
	input[type=password]::-ms-reveal,
	input[type=password]::-ms-clear {
		display: none;
	}
}


/* IE9,10 */

@media screen and (min-width:0\0) {
	input[required] {
		border: none !important;
		box-shadow: none;
		outline: none !important;
	}
	input:required:invalid {
		outline: none !important;
		border: none !important;
	}
	textarea:required:invalid {
		outline: none !important;
		border: none !important;
	}
	select:required:invalid {
		outline: none !important;
		border: none !important;
	}
	input[type=password]::-ms-reveal,
	input[type=password]::-ms-clear {
		display: none;
	}
}


/* IE CSS end */

.lang-flag {
	padding-right: 10px;
	width:28px;
}
.language-icon {
	fill:#EFEFEF !important;
}
.drawer-logo-container {
	display: none;
}
/*Drawer - Header */
@media (min-width: 960px) {

	header .MuiButton-root {
		margin-top:11px !important;

	}
	.language-icon {		
		margin-top:11px !important;
	}
	header .Mui-selected>.MuiTab-wrapper {
		width:10%;
		/* border-bottom:2px solid #FFFFFF; */
		word-break: keep-all;
	}
	header .MuiTab-wrapper {
		
		border-bottom:2px solid transparent;
	}
	header .profileTab.Mui-selected>.MuiTab-wrapper {
	
		border-bottom:none;
	}
	header .profileTab .profileAvatar {
	
		top:4px;
	}
}

@media (max-width:959px) {
	.my-dropdown-menu ul li {
		font-size: 0.8rem !important;
		min-height:26px !important;
		line-height:24px;
	}
	.my-secure-dropdown-menu ul a {
		font-size: 0.8rem !important;
		min-height:26px !important;
		line-height:24px;
	}	
	.MuiDrawer-paper {
		background:#0099ff !important;
	}
	.MuiTabs-flexContainerVertical{
		background: rgba(0,0,0,0.3) !important;
		padding:10px 20px !important;
		min-height:150px;
		
	}
	.drawer-logo-container {
		display: block;
	}
	.drawer-logo {
		display: block !important;
		width: auto !important;
		height:70px !important;
		padding-top: 2px;
		padding-bottom:10px;
		margin:0 auto !important;
		margin-top: -10px !important;

	}
	.headerLoginBtn {
		color:#FFFFFF !important;
		border:1px solid whitesmoke !important;
		margin-top: 15px !important;
	}	
	
}
[class="Component-indicator"] span {
	background: none;

}
header .MuiTabs-indicator span {
	background: none;
}
/* Loader */
#loader {
	position: absolute;
	left: 50%;
	top: 50%;
	z-index: 1;
	margin: -75px 0 0 -75px;
	border: 8px solid #f3f3f3;
	border-radius: 50%;
	border-top: 8px solid #0099FF;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
  }
  
  @-webkit-keyframes spin {
	0% { -webkit-transform: rotate(0deg); }
	100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
	0% { transform: rotate(0deg); }
	100% { transform: rotate(360deg); }
  }
/* Loader End */