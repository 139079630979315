ol {
    counter-reset: item;
    font-size: small;
    padding: 0 0 0 5%;
}
ol li {
    display: block;
    position: relative;
}
ol li:before {
    content: counters(item, ".")".";
    counter-increment: item;
    position: absolute;
    margin-right: 100%;
    right: 5px; /* space between number and text */
}