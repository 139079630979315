.changePasswordStyle .MuiFormLabel-root.MuiInputLabel-root.input-label {
	color: rgb(101, 101, 101) !important;
	/* color: black !important; */
	font-weight: 400 !important;
	text-transform: uppercase !important;
	font-size: small !important;
	opacity: 1 !important;
}

.changePasswordStyle .MuiInputBase-input.MuiInput-input {
	color: #000 !important;
}

.changePasswordStyle .input-label+.MuiInput-underline:before,
.changePasswordStyle .input-label+.MuiInput-underline:after {
	border-bottom: 1px solid rgb(20 188 206 / 42%) !important;
}

.changePasswordStyle .MuiInput-underline.Mui-error:after {
	transform: scaleX(1) !important;
	border-bottom: 1px solid #f44336 !important;
}

input::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}

input:focus::-webkit-input-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}


/* Firefox < 19 */

input:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}

input:focus:-moz-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}


/* Firefox > 19 */

input::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}

input:focus::-moz-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}


/* Internet Explorer 10 */

input:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}

input:focus:-ms-input-placeholder {
	color: rgb(101, 101, 101) !important;
	font-size: small !important;
}